// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "DondiWhite-module--CarrouselWrapper2--21gjM";
export var DescriptionWrapper = "DondiWhite-module--DescriptionWrapper--WgbQP";
export var DownloaderWrapper = "DondiWhite-module--DownloaderWrapper--ICQkn";
export var EventWrapper = "DondiWhite-module--EventWrapper--64Nen";
export var ImageWrapper = "DondiWhite-module--ImageWrapper--2ay9k";
export var ImagesWrapper = "DondiWhite-module--ImagesWrapper--5P96q";
export var MobileTile = "DondiWhite-module--MobileTile--YLHYH";
export var PdpWrapper = "DondiWhite-module--PdpWrapper--QL0Sg";
export var PhotosWrapper = "DondiWhite-module--PhotosWrapper--Rx6SC";
export var TitleWrapper = "DondiWhite-module--TitleWrapper--3qOcM";
export var Wrapper = "DondiWhite-module--Wrapper--THpH1";