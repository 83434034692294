import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./DondiWhite.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Carrousel from "../../../../components/Carrousel";
import Dondi1 from "./../../../../res/Dondi/exposition/dondi1.jpg"
import Dondi2 from "./../../../../res/Dondi/exposition/dondi2.jpg"
import Dondi3 from "./../../../../res/Dondi/exposition/dondi3.jpg"
import Dondi4 from "./../../../../res/Dondi/exposition/dondi4.jpg"
import Dondi5 from "./../../../../res/Dondi/exposition/dondi5.jpg"
import Dondi6 from "./../../../../res/Dondi/exposition/dondi6.jpg"
import Dondi7 from "./../../../../res/Dondi/exposition/dondi7.jpg"
import Tile from "./../../../../res/Dondi/exposition/dondi-tile.png";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: Dondi2, name: "Dondi" },
    { src: Dondi3, name: "Dondi" },
    { src: Dondi5, name: "Dondi" },
  ],
};

const DondiWhite = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
     <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="dondi"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>DONDISM<h2 style={{paddingLeft: "16px"}}>DONDI - Marseille - 2018</h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="dondi"/>
        <br />
        <p>
          DONDI WHITE - dit DONDI - est l’un des « pères » fondateurs du graffiti « à la New-Yorkaise » des années 70-80 ; Le King du graffiti figuratif New-Yorkais et du lettrage ; Un roi incontesté de mythiques « wholes cars » - comme le fameux children of the grave réalisé en 1980. Son talent graphique lui permit de faire évoluer son esthétisme - passant des rames de métro à la toile pour devenir un des artistes majeurs du XXe siècle.
          </p>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
       
      {/* <div className={InfosWrapper}>
        <div className={DetailsWrapper}>
          <p className={SectionTitle}>Détails</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <p>Date:</p>
            <div className={Spacer}></div>
            <p>Avril 2018</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Event category:</p>
            <div className={Spacer}></div>
            <p>Exhibitions</p>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={OrganisationWrapper}>
          <p className={SectionTitle}>Organisation</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>Ghost Galerie</p>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Téléphone:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="tel:+33633131465">
              +33(0)6.33.13.14.65
            </a>
          </div>
          <div className={PaddingTop10}></div>
          <div className={RowWrapper}>
            <p>Email:</p>
            <div className={Spacer}></div>
            <a className={LinkWrapper} href="mailto:contact@ghostgalerie.com">
              contact@ghostgalerie.com
            </a>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={AddressWrapper}>
          <p className={SectionTitle}>Adresse</p>
          <div className={LineWrapper}></div>
          <div className={RowWrapper}>
            <div className={Spacer}></div>
            <p>2 rue de Bélloi, 13006 Marseille</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DondiWhite;
